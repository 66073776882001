<template>
  <div style="width: 100%">
    <div class="inputs">
      <b-form-group
        :label="$t('StartDate')"
      >
        <date-picker
          v-model="from"
          value-type="format"
          :placeholder="$t('SelectD')"
          :format="'YYYY-MM-DD'"
          :clearable="false"
          :lang="lang"
        />
      </b-form-group>
      <b-form-group
        :label="$t('EndDate')"
      >
        <date-picker
          v-model="to"
          value-type="format"
          :placeholder="$t('SelectD')"
          :format="'YYYY-MM-DD'"
          :clearable="false"
          :lang="lang"
        />
      </b-form-group>
      <b-form-group
        :label="$t('Supplier')"
        style="width: 15%;"
      >
        <vue-select
          v-model="supplierId"
          :options="getSupplierDropDown"
          label="supplierName"
          :reduce="(e) => e.supplierId"
          :placeholder="$t('Main')"
          aria-describedby="input-1-live-feedback"
          :clearable="true"
          @input="handleChange"
        />
        <b-form-invalid-feedback
          id="input-1-live-feedback"
        >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
      </b-form-group>
      <PdfDocument
        :method-name="'costCardBySupplier'"
        :getter-name="'getCostCardBySupplier'"
        :pdf-name="'PurchaseBook'"
        :is-pagination="false"
        :header-name="header"
        :object-value="objectPDF"
        :start-date="from"
        :end-date="to"
        :pdf-title="$t('PurchaseBook')"
        style="margin-top: 1.3%;"
      />
      <button
        v-b-tooltip.hover="$t('DownloadExcel')"
        style="border: 0; width: 48px; height: 37px; background: #FF274F; border-radius: 5px; margin-top: 1.3%"
        @click="downloadEXCEL"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          class="bi bi-file-earmark-spreadsheet"
          viewBox="0 0 16 16"
        >
          <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
        </svg>
      </button>
    </div>
    <div style="height: 72vh; overflow-y: scroll">
      <table
        ref="exportable_table"
        class="team_table"
      >
        <thead style="position: sticky; top: -2px">
          <tr>
            <th>
              {{ $t('SupplierName') }}
            </th>
            <th>
              {{ $t('Created') }}
            </th>
            <th>
              {{ $t('MaterialVariantName') }}
            </th>
            <th>
              {{ $t('Amount') }}
            </th>
            <th>
              {{ $t('Price') }}
            </th>
            <th>
              {{ $t('TotalPrice') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(card, index) in getCostCardBySupplier"
            :key="index"
          >
            <td>{{ card.supplierName }}</td>
            <td>{{ convertUTCDateToLoacalDate(card.created) }}</td>
            <td>{{ card.materialVariantName }}</td>
            <td>{{ numbersWithcomma(card.amount) }}</td>
            <td>{{ numbersWithcomma(card.orderedPrice) }}</td>
            <td>{{ numbersWithcomma(card.amount * card.orderedPrice) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment'
import 'vue2-datepicker/index.css';
import 'jspdf-autotable';
import { mapGetters, mapActions } from 'vuex';
import PdfDocument from '@/components/PdfDocument/DownloadPDF.vue'
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default {
  components: {
    DatePicker,
    PdfDocument,
  },
  data() {
    return {
      supplierId: null,
      from: null,
      to: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      header: [
        {
          supplierName: 'Supplier Name', created: 'Created', materialVariantName: 'Material Variant Name', amount: 'Amount', orderedPrice: 'Ordered Price', totalPrice: 'Total Price',
        },
      ],
      objectPDF: {
        from: this.from,
        to: this.to,
        supplierId: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getSuppliers',
      'getCostCardBySupplier',
      'getSupplierDropDown',
    ]),
  },
  watch: {
    from(newValue) {
      if (newValue && this.to) {
        this.callCardCost();
      }
    },
    to(newValue) {
      if (newValue && this.from) {
        this.callCardCost();
      }
    },
    supplierId(newValue) {
      if (newValue === null && this.from && this.to) {
        this.fetchAllSuppliers();
      } else if (newValue && this.from && this.to) {
        this.callCardCost();
      }
    },
  },
  mounted() {
    this.suppliersDropDown({
      isActive: true,
    })
  },
  methods: {
    ...mapActions(['costCardBySupplier', 'suppliersDropDown']),
    handleChange() {
      this.costCardBySupplier({
        from: this.from,
        to: this.to,
        supplierId: null,
      })
    },
    callCardCost() {
      this.costCardBySupplier({
        from: this.from,
        to: this.to,
        supplierId: this.supplierId,
      })
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    generateTableContentCSV(data) {
      const headers = ['Supplier Name', 'Created', 'Material Variant Name', 'Amount', 'Ordered Price'];
      const body = data.map((item) => {
        return [
          item.supplierName,
          this.convertUTCDateToLoacalDate(item.created),
          item.materialVariantName,
          this.numbersWithcomma(item.amount),
          this.numbersWithcomma(item.orderedPrice),
        ]
      });
      return { headers, body };
    },
    generateExcel(tableContent) {
      const { headers, body } = tableContent;
      const sheetData = [headers, ...body];

      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      return excelData;
    },
    downloadEXCEL() {
      this.costCardBySupplier({
        from: this.from,
        to: this.to,
        supplierId: this.supplierId,
      }).then(() => {
        const tableContent = this.generateTableContentCSV(this.getCostCardBySupplier)

        const totalPages = this.getCostCardBySupplier;
        const additionalPromises = [];
        for (let pageNumber = 2; pageNumber <= totalPages; pageNumber++) {
          additionalPromises.push(this.downloadPage(pageNumber));
        }

        Promise.all(additionalPromises).then((additionalTableContents) => {
          additionalTableContents.forEach((additionalTableContent) => {
            tableContent.body = tableContent.body.concat(additionalTableContent.body);
          });

          const excelData = this.generateExcel(tableContent);
          FileSaver.saveAs(excelData, 'PurchaseBook.xlsx');
        })
      });
    },
  },
}
</script>

<style>
.pt-0 {
  padding-bottom: 0px !important;
}
.inputs {
  display: flex;
  width: 100%;
  gap: 5px;
  margin-top: 1%;
}
.team_table td {
  padding: 16px 9px !important;
}
</style>
