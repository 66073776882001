<template>
  <div>
    <b-modal
      id="add-cost-revenue"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      size="md"
      :title="$t('AddCostRevenue')"
      hide-footer
    >
      <b-tabs v-model="tabIndex">
        <b-tab>
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("IncomeExpense") }}</strong>
          </template>
          <b-form>
            <div style="display: flex; flex-direction: column; width: 100%; justify-content: space-between;">
              <div style="display: flex; justify-content: space-between;gap: 10px;">
                <b-form-group
                  :label="$t('Account')"
                  style="width: 100%;padding-bottom: 10px;"
                >
                  <vue-select
                    v-model="$v.form.accountId.$model"
                    :options="getAccountDropDown"
                    label="name"
                    :reduce="(e) => e.accountId"
                    :placeholder="$t('Account')"
                    aria-describedby="input-1-live-feedback"
                  />
                  <span
                    v-show="formSubmitted && !$v.form.accountId.required"
                    style="font-size: 0.875em; color: #dc3545;"
                  >{{ $t('SelectAccount') }}</span>
                </b-form-group>
              </div>
              <div>
                <b-form-group
                  :label="$t('TransactionType')"
                  style="width: 100%;padding-bottom: 10px;"
                >
                  <vue-select
                    v-model="$v.form.transactionType.$model"
                    :placeholder="$t('TransactionType')"
                    :options="transactionTypes"
                    :reduce="(e) => e.value"
                    label="name"
                    aria-describedby="input-2-live-feedback"
                  />
                  <span
                    v-show="formSubmitted && !$v.form.transactionType.required"
                    style="font-size: 0.875em; color: #dc3545;"
                  >{{ $t('SelectTransactionType') }}</span>
                </b-form-group>
              </div>
              <div v-if="form.transactionType">
                <div style="display: flex; justify-content: space-between;gap: 10px;">
                  <b-form-group
                    :label="$t('BudgetCode')"
                    style="width: 100%;padding-bottom: 10px;"
                  >
                    <vue-select
                      v-model="$v.form.budgetCodeAndName.$model"
                      :options="getBudgetByTranscation"
                      label="budgetCodeAndName"
                      :reduce="(e) => e.id"
                      :placeholder="$t('BudgetType')"
                      aria-describedby="input-1-live-feedback"
                    />
                    <span
                      v-show="formSubmitted && !$v.form.budgetCodeAndName.required"
                      style="font-size: 0.875em; color: #dc3545;"
                    >{{ $t('SelectBudgetCode') }}</span>
                  </b-form-group>
                </div>
                <div>
                  <b-form-group
                    :label="$t('BudgetSubCode')"
                    style="width: 100%;padding-bottom: 10px;"
                  >
                    <vue-select
                      v-model="$v.form.budgetSubCode.$model"
                      :options="getBudgetSubTypeByBudgetId"
                      label="budgetSubCodeAndName"
                      :reduce="(e) => e.budgetSubTypeId"
                      :placeholder="$t('BudgetSubType')"
                      aria-describedby="input-1-live-feedback"
                      @change="updateBudgetSubTypeId($event)"
                    />
                    <span
                      v-show="formSubmitted && !$v.form.budgetSubCode.required"
                      style="font-size: 0.875em; color: #dc3545;"
                    >{{ $t('SelectBudgetSubCode') }}</span>
                  </b-form-group>
                </div>
                <div style="display: flex; justify-content: space-between;gap: 10px;">
                  <b-form-group
                    :label="$t('Price')"
                    style="width: 100%;padding-bottom: 10px;"
                  >
                    <div style="position: absolute;">
                      <div v-if="form.currency == '€'">
                        <b-icon-currency-euro style="margin-top: 10px;margin-left: 5px;" />
                      </div>
                      <div v-else-if="form.currency == '$'">
                        <b-icon-currency-dollar style="margin-top: 10px;margin-left: 5px;" />
                      </div>
                    </div>
                    <b-form-select
                      v-model="form.currency"
                      dropup
                      required
                      :options="currencies"
                      :aria-placeholder="$t('SelectOrderType')"
                      aria-describedby="input-1-live-feedback"
                      class="currency"
                    />
                    <b-form-input
                      v-model="$v.form.price.$model"
                      :placeholder="$t('1000.00')"
                      aria-describedby="input-2-live-feedback"
                    />
                    <span
                      v-show="formSubmitted && !$v.form.price.required"
                      style="font-size: 0.875em; color: #dc3545;"
                    >{{ $t('AddPrice') }}</span>
                  </b-form-group>

                  <b-form-group
                    :label="$t('PaidPrice')"
                    style="width: 100%;padding-bottom: 10px;"
                  >
                    <b-form-input

                      v-model="$v.form.paidPrice.$model"
                      :placeholder="$t('500.00')"
                      aria-describedby="input-2-live-feedback"
                    />
                    <span
                      v-show="formSubmitted && !$v.form.paidPrice.required"
                      style="font-size: 0.875em; color: #dc3545;"
                    >{{ $t('AddPaidPrice') }}</span>
                  </b-form-group>
                </div>
                <div style="display: flex; justify-content: space-between;gap: 10px;">
                  <b-form-group
                    :label="$t('Supplier')"
                    style="width: 100%;padding-bottom: 10px;"
                  >
                    <vue-select
                      v-model="form.supplierId"
                      :options="getOtherSuppliers"
                      label="supplierName"
                      :reduce="(e) => e.supplierId"
                      :placeholder="$t('Supplier')"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback
                      id="input-1-live-feedback"
                    >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div style="width: 100%;">
                  <b-form-group
                    :label="$t('TransactionDescription')"
                    style="width: 100%;padding-bottom: 10px;"
                  >
                    <b-form-textarea
                      id="textarea"
                      v-model="form.notes"
                      :placeholder="$t('Description')"
                    />
                  </b-form-group>
                </div>
                <div style="margin: 5% 1%; display: flex">
                  <p class="p-0 m-0 ml-50">
                    {{ $t('IncludeTax') }}
                    <toggle-button
                      :value="includeTax"
                      :sync="true"
                      color="#242F6E"
                      @change="includeTax = !includeTax"
                    />
                  </p>
                  <div
                    v-if="includeTax == true"
                    style=" margin-left: 10px; margin-bottom: 10px"
                  >
                    <span>18%</span>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </b-tab>
        <b-tab>
          <template #title>
            <strong
              style="font-weight: 500; color: black"
            >{{ $t("Invoice") }}</strong>
          </template>
          <div style="display: flex; flex-direction: column; margin-top: 20px">
            <div style="display: flex; gap: 10px;">
              <AddFile
                ref="addFile1"
                :folder="'IncomeExpenseCost'"
                :label-text="$t('UploadInvoice')"
                :file-data-type="'Invoice'"
                :file-name="form.invoiceDocument"
              />
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <div class="buttonsEverywhere">
        <button
          v-if="tabIndex == 1"
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          v-else
          :disabled="form.accountId == null || form.budgetCodeAndName == null || form.price == null || form.paidPrice == null || form.supplierId == null"
          class="buttonSubmit"
          @click="tabIndex++"
        >
          {{ $t("Next") }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import AWS from 'aws-sdk';
import {
  required,
} from 'vuelidate/lib/validators';
import AddFile from '../../../modals/AddFile.vue'
import { AWSurl } from '../../../domainConfig';
// import moment from 'moment-timezone';

export default {
  components: {
    // DatePicker,
    AddFile,
  },
  mixins: [validationMixin],
  props: [],
  data() {
    return {
      page: 1,
      pageSize: 15,
      includeTax: false,
      bucketName: AWSurl.bucketName,
      tabIndex: 0,
      formSubmitted: false,
      form: {
        supplierId: null,
        accountId: null,
        budgetCodeAndName: null,
        budgetSubCode: null,
        price: null,
        paidPrice: null,
        notes: null,
        transactionType: null,
        budgetSubTypeId: null,
        currency: '',
        taxRate: 0,
        invoiceDocument: [],
      },
      currencies: ['€', '$'],
      transactionTypes: [
        { name: 'Debit', value: 'Debit' },
        { name: 'Credit', value: 'Credit' },
      ],
    };
  },
  validations: {
    form: {
      accountId: {
        required,
      },
      budgetCodeAndName: {
        required,
      },
      budgetSubCode: {
        required,
      },
      transactionType: {
        required,
      },
      price: {
        required,
      },
      paidPrice: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters([
      'getAccountDropDown',
      'getBudgetTypeSelection',
      'getBudgetSubTypeByBudgetId',
      'getOtherSuppliers',
      'getBudgetByTranscation',
    ]),
    getBudgetTypeSelectionWithId() {
      return this.getBudgetByTranscation.map((option) => {
        return {
          budgetCodeAndName: option.budgetCode,
          id: option.id,
        };
      });
    },
    filteredBudgetSubTypes() {
      // Filter budgetSubTypes based on selected budgetCodeAndName
      if (!this.form.budgetCodeAndName) return [];

      const [id] = this.form.budgetCodeAndName.split(',');
      const budgetSubTypes = this.getBudgetSubTypeByBudgetId[id] || [];

      return budgetSubTypes;
    },
  },
  watch: {
    'form.transactionType': function (newVal) {
      if (newVal) {
        this.budgetadedTranscation({
          TransactionType: newVal,
        })
        this.form.budgetCodeAndName = null
        this.form.budgetSubCode = null
      }
    },
    'form.budgetCodeAndName': function (newVal) {
      console.log('Selected budget code and name:', newVal);
      if (newVal) {
        const [id] = newVal.split(',');
        console.log('Selected budget type ID:', id);
        // No need to set budgetSubTypeId here
        this.budgetSubTypeByBudgetId({ budgetTypeId: id });
        this.form.budgetSubCode = null
      }
    },
    'form.budgetSubCode': function (newVal) {
      console.log('Selected budget sub code:', newVal);
      if (newVal && typeof newVal === 'object') {
        this.form.budgetSubTypeId = newVal.id;
      } else {
        this.form.budgetSubTypeId = null;
      }
    },
    includeTax(newIncludeTax) {
      if (newIncludeTax) {
        this.form.taxRate = 18;
      } else {
        this.form.taxRate = 0;
      }
    },
  },
  mounted() {
    this.accountsDropDown();
    // this.budgetTypesSelection();
    this.suppliersOther({
      pageNumber: this.page,
      pageSize: this.pageSize,
      isActive: true,
      name: null,
    })
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  methods: {
    ...mapActions([
      'accountsDropDown',
      'budgetTypesSelection',
      'budgetSubTypeByBudgetId',
      'addCostRevenue',
      'suppliersOther',
      'budgetadedTranscation',
    ]),
    // validateState(name) {
    //   const { $dirty, $error } = this.$v.form[name];
    //   return $dirty ? !$error : null;
    // },
    fetchBudgetCodeData() {
      this.budgetTypesSelection();
    },
    fetchBudgetSubCodeData() {
      if (this.form.budgetCodeAndName) {
        const [id] = this.form.budgetCodeAndName.split(',');
        this.budgetSubTypeByBudgetId({ budgetTypeId: id });
      }
    },
    updateBudgetSubTypeId(selectedValue) {
      console.log('Selected budget sub code:', selectedValue);
      if (selectedValue && selectedValue.id) {
        this.form.budgetSubTypeId = selectedValue.id;
      } else {
        this.form.budgetSubTypeId = null;
      }
    },
    onChange(event) {
      const files = event.target.files;
      if (files.length <= 3) {
        this.fileData = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              }
              resolve(fileData);
            };
          });
          promise.then((fileData) => {
            this.fileData.push(fileData);
          });
          console.log(this.fileData, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.')
      }
    },
    async onSubmit() {
      await this.$refs.addFile1.uploadFile();
      const payload = {
        accountId: this.form.accountId,
        price: this.form.price,
        paidPrice: this.form.paidPrice,
        notes: this.form.notes,
        transactionType: this.form.transactionType,
        budgetSubTypeId: this.form.budgetSubCode,
        currency: this.form.currency,
        taxRate: this.form.taxRate,
        fileUrls: this.form.invoiceDocument,
        supplierId: this.form.supplierId,
      };
      this.formSubmitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('addCost', payload);
      this.$refs.modal.hide();
      this.resetForm();
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.accountId = null;
      this.form.budgetCodeAndName = null;
      this.form.budgetSubCode = null;
      this.form.price = null;
      this.form.paidPrice = null;
      this.form.notes = null;
      this.form.transactionType = null;
      this.form.budgetSubTypeId = null;
      this.form.taxRate = null;
      this.form.supplierId = null;
      this.form.invoiceDocument = [];
      this.formSubmitted = false;
      this.tabIndex = 0;
    },
  },
}
</script>

<style lang="scss" scoped>
  .currency{
    position:absolute;
    left:85%;
    display:block;
    padding: 0.4rem 0.70rem;
    font-size: 1rem;
    margin-top: 1px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
  }
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
