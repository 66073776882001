<template>
  <b-sidebar
    id="transaction-details"
    ref="modal"
    header-class="headerModalToggle"
    right
    shadow
    no-close-on-esc
    width="55%"
    @close="onCancelToggle"
  >
    <template #title>
      <strong style="color: #101828">{{ `${$t("AccountTransactions")}` }}</strong>
    </template>
    <template v-slot:header-close>
      <button
        style="color: #98A2B3;height: 60px;"
        class="fa fa-close"
        @click="onCancelToggle"
      />
    </template>
    <div style="margin: 2% 1%;">
      <table
        class="team_table"
      >
        <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
          <tr>
            <th>
              {{ $t('AccountName') }}
            </th>
            <th>
              {{ $t('Amount') }}
            </th>
            <th>
              {{ $t('Created') }}
            </th>
            <th>
              {{ $t('Currency') }}
            </th>
            <th>
              {{ $t('ExecutorName') }}
            </th>
            <th>
              {{ $t('Description') }}
            </th>
            <th>
              {{ $t('TaxRate') }}
            </th>
            <th>
              {{ $t('TransactionType') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(items, index) in getDetailsByTransactionId"
            :key="index"
          >
            <td>{{ items.accountName }}</td>
            <td>{{ numbersWithcomma(items.amount) }}</td>
            <td>{{ convertUTCDateToLoacalDate(items.created) }}</td>
            <td>{{ items.currency }}</td>
            <td>{{ items.executorFullName }}</td>
            <td>{{ items.notes }}</td>
            <td>{{ items.taxRate }}</td>
            <td>{{ items.transactionType }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-sidebar>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'

export default {
  props: ['transactionId'],

  computed: {
    ...mapGetters(['getDetailsByTransactionId']),
  },
  methods: {
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 10px 16px;
}
</style>
