<template>
  <div>
    <b-modal
      id="view-invoice"
      ref="modal"
      header-class="headerModal"
      :title="$t('Invoice')"
      centered
      size="md"
      hide-footer
      no-close-on-backdrop
      @close="onCancel"
    >
      <div>
        <b-form-group
          id="input-group-6"
          style="font-weight: bold"
          class="mb-0"
        />
        <div>
          <div class="files">
            <div
              style="display: flex; flex-direction: row !important;"
            >
              <div
                v-for="(img, index) in getIncomeExpenseFileUrl"
                :key="img"
                style="background-color: transparent; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;gap: 20px;"
              >
                <img
                  :src="img"
                  :class="{'photo': true, 'hovered': hoveredIndex === img.photoId, 'second-photo': index === 1, 'third-photo': index === 2}"
                  style="width: 40px"
                  @click="openLightbox(index)"
                >
                <button
                  style="border: 0; width: 48px; height: 36px; background: #FF274F; border-radius: 5px; margin-right: 10px;"
                  @click="downloadFile(img)"
                >
                  <b-icon-download style="color: white;" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <vue-easy-lightbox
          :visible="lightboxVisible"
          :index="lightboxIndex"
          :imgs="getIncomeExpenseFileUrl"
          @hide="closeLightbox"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import { mapGetters } from 'vuex';

export default {
  components: {
    VueEasyLightbox,
  },
  props: ['invoicesDocument'],
  data() {
    return {
      lightboxVisible: false,
      lightboxIndex: 0,
      hoveredIndex: null,
      visible: false,
    }
  },
  computed: {
    ...mapGetters(['getIncomeExpenseFileUrl']),
  },
  methods: {
    openLightbox(index) {
      this.lightboxIndex = index;
      this.lightboxVisible = true;
    },
    closeLightbox() {
      this.lightboxVisible = false;
    },
    show() {
      this.visible = true
    },
    downloadFile(fileUrl) {
      // Extract filename from the URL
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      console.log('aa', fileName)
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      console.log('b', link)
      console.log('c', fileUrl)
      console.log('d', fileName)



      // Trigger a click on the anchor element to start the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
}
</script>
