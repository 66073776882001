<template>
  <div>
    <ReportFinance />
  </div>
</template>

<script>
import ReportFinance from '../components/employeePayments/ReportFinance.vue'

export default {
  components: {
    ReportFinance,
  },
}
</script>

<style scoped lang="scss">

</style>
