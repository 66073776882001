<template>
  <div>
    <b-modal
      id="pay-expenses"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      size="md"
      :title="$t('Pay')"
      hide-footer
    >
      <b-form>
        <div style="display: flex; justify-content: space-between;gap: 10px;">
          <b-form-group
            :label="$t('Price')"
            style="width: 100%;padding-bottom: 10px;"
          >
            <div style="position: absolute;">
              <div v-if="form.currency == '€'">
                <b-icon-currency-euro style="margin-top: 10px;margin-left: 5px;" />
              </div>
              <div v-else-if="form.currency == '$'">
                <b-icon-currency-dollar style="margin-top: 10px;margin-left: 5px;" />
              </div>
            </div>
            <b-form-select
              v-model="form.currency"
              dropup
              required
              :options="currencies"
              :aria-placeholder="$t('SelectOrderType')"
              aria-describedby="input-1-live-feedback"
              class="currency"
            />
            <b-form-input
              v-model="form.paidAmount"
              :placeholder="$t('Price')"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
        </div>
        <div>
          <div style="width: 100%;">
            <b-form-group
              :label="$t('Description')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-textarea
                id="textarea"
                v-model="form.notes"
                style="padding: 5px 10px;"
                :placeholder="$t('Description')"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('Account')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <vue-select
                v-model="form.accountId"
                :options="getAccountDropDown"
                label="name"
                :reduce="(e) => e.accountId"
                :placeholder="$t('Account')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div style="margin: 5% 1%; display: flex">
            <p class="p-0 m-0 ml-50">
              {{ $t('IncludeTax') }}
              <toggle-button
                :value="includeTax"
                :sync="true"
                color="#242F6E"
                @change="includeTax = !includeTax"
              />
            </p>
            <div
              v-if="includeTax == true"
              style=" margin-left: 10px; margin-bottom: 10px"
            >
              <span>18%</span>
            </div>
          </div>
        </div>
      </b-form>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['incomeExpenseId'],
  data() {
    return {
      includeTax: false,
      form: {
        paidAmount: null,
        notes: null,
        accountId: null,
        currency: null,
        taxRate: 0,
        costRevenueId: null,
      },
      currencies: ['€', '$'],
      transactionTypes: [
        { name: 'Debit', value: 'Debit' },
        { name: 'Credit', value: 'Credit' },
      ],
    }
  },
  computed: {
    ...mapGetters(['getAccountDropDown']),
  },
  watch: {
    includeTax(newIncludeTax) {
      if (newIncludeTax) {
        this.form.taxRate = 18;
      } else {
        this.form.taxRate = 0;
      }
    },
    incomeExpenseId(value) {
      this.form.costRevenueId = value
    },
  },
  methods: {
    onSubmit() {
      this.$emit('addPayment', this.form)
      this.$refs.modal.hide();
      this.resetForm();
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.paidAmount = null;
      this.form.currency = null;
      this.form.accountId = null;
      this.form.notes = null;
      this.form.taxRate = null;
    },
  },
}
</script>

<style lang="scss" scoped>
  .currency{
    position:absolute;
    left:85%;
    display:block;
    padding: 0.4rem 0.70rem;
    font-size: 1rem;
    margin-top: 1px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
  }
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
